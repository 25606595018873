import Vue from 'vue'

const Site = new Vue({
    'el' : '#drivescout-nav',
    data(){ 
        return {
            mobileUI: {
                'open' : false
            }
        }
    }
}); //.mount('#drivescout-nav')

export default Site;